
import { AboutDataModel } from './about-data-model';

export const ABOUT_DATA_EN: AboutDataModel = {
    pageTitle: 'About us',
    pageDescription: 'Our company has several decades of experience gained in metalworking with CNC technology and each of our colleagues, suppliers, and subcontractors is committed to maintaining high-quality and standards. Our mission is to offer complex solutions for manufacturing components to our Partners, including the arrangment and supervision of necessary heat and surface treatments. We have a modern machinery at our disposal and besides manufacturing, our company has experience in product development and product planning processes. Our center is located in the Szigetszentmiklós Industrial Park. We pursue long-term cooperation with our Partners whom we can also support with warehousing and consignment services.',
    cards: [       
        {
            title: 'Minőség',
            description: 'Munkatársunk, beszállítóink, alvállalkozóink elkötelezettek a minőség fenntartása mellett'
            
        },
        {
            title: 'Precizitás',
            description: 'Kiterjedt megoldásokat kínálunk a Partnereink számára'
        },
        {
            title: 'Együttműködés',
            description: 'Hosszú távú együttműködésre törekszünk, melyet változatos szolgáltatásainkkal támogatunk'
        }
    ]
};

