import { Component } from '@angular/core';
import { WorkProductsDataModel } from './data/work-products-data-model';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { WORK_PRODUCTS_DATA } from './data/work-products-data';
import { WORK_PRODUCTS_DATA_DE } from './data/work-products-data-de';
import { WORK_PRODUCTS_DATA_EN } from './data/work-products-data-en';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { WORK_PRODUCTS } from './data/images';

@Component({
  selector: 'app-work-pieces',
  templateUrl: './work-pieces.component.html',
  styleUrls: ['./work-pieces.component.scss']
})
export class WorkPiecesComponent extends PageContentTranslateBase<WorkProductsDataModel> {

  readonly workProducts = WORK_PRODUCTS;

  readonly languages = {
    [Languages.Hu]: WORK_PRODUCTS_DATA,
    [Languages.De]: WORK_PRODUCTS_DATA_DE,
    [Languages.En]: WORK_PRODUCTS_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }
}
