import { WhyUsDataModel } from './why-us-data-model';

export const WHY_US_DATA_DE: WhyUsDataModel = {
    pageTitle: 'Warum Sie uns wählen sollten?',
    pageDescription: 'Unsere Ingenieure verfügen über jahrelange Erfahrungen im Schalttisch, in der Produktion, in der Automobilindustrie, in der Automatisierung, in der Oberflächenbehandlung Technologien.',
    list: [
        {
            title: 'Erfahrungen',
            description: 'Internationale Markt-Erfahrung, die Erkenntnisse der Normen und der Geschäftserwartungen.'
        },
        {
            title: 'Flexibilität',
            description: 'Wegen der Flexibilität unserer Organisation, beziehungsweise der Struktur, anpassbare Lösungen.'
        },
        {
            title: 'Garantie',
            description: 'Langjährige, ständig kontrollierte, qualitative Lieferung Hintergrund.'
        },
        {
            title: 'Sicher',
            description: 'Schnelles Angebot, ergebnisorientierte Kommunikation.'
        },
        {
            title: 'International',
            description: 'Englisch- und Deutschkenntnisse.'
        },
        {
            title: 'Schnelligkeit',
            description: 'Kurze Fristen in der Produktion.'
        }
    ]
}