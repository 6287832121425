import { Component } from '@angular/core';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { ContactDataModel } from './data/contact-data-model';
import { CONTACT_DATA } from './data/contact-data';
import { CONTACT_DATA_DE } from './data/contact-data-de';
import { CONTACT_DATA_EN } from './data/contact-data-en';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends PageContentTranslateBase<ContactDataModel> {

  readonly languages = {
    [Languages.Hu]: CONTACT_DATA,
    [Languages.En]: CONTACT_DATA_EN,
    [Languages.De]: CONTACT_DATA_DE
  }

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }
}
