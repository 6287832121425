import { QualityDataModel } from './quality-data-model';

export const QUALITY_DATA_DE: QualityDataModel = {
    pageTitle: 'Die Qualität',
    pageDescription: 'In unserer Firmenphilosophie zeigen wir unsere persönliche und geschäftliche Erfahrungen, dass die Qualität nur mit hervorragenden technischne Lösungen und regelmäßigen gewarteten Maschinenpark, fehlerfrei und ständig kontrollierte Rohstoffe, Werkzeuge und Messgeräte erstellt werden kann. Unsere Firma hat natürlich integrierte ISO-Zertifizierung.',
    certData: [
        {
            title: 'ISO 9001:2015',
            url: '/assets/quality/1_angol.png',
        },{
            title: 'ISO 14001:2015',
            url: '/assets/quality/2_angol.png'
        }
    ]
}
