import { WorkProduct } from './work-product';

export const WORK_PRODUCTS: WorkProduct[] = [
    {
        imgUrl: '/assets/workelements/1.jpg',
        text: '1', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/2.jpg',
        text: '2', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/3.jpg',
        text: '3', 
        cols: 2, 
        rows: 4, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/4.jpg',
        text: '4', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/5.jpg',
        text: '5', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/6.jpg',
        text: '6', 
        cols: 2, 
        rows: 4, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/7.jpg',
        text: '7', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/8.jpg',
        text: '8', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/9.jpg',
        text: '9', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    },
    {
        imgUrl: '/assets/workelements/10.jpg',
        text: '10', 
        cols: 1, 
        rows: 2, 
        color: 'transparent'
    }        
]