import { Component } from '@angular/core';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { WhyUsDataModel } from './data/why-us-data-model';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { WHY_US_DATA } from './data/why-us-data';
import { WHY_US_DATA_DE } from './data/why-us-data-de';
import { WHY_US_DATA_EN } from './data/why-us-data-en';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})
export class WhyUsComponent extends PageContentTranslateBase<WhyUsDataModel> {

  readonly icons: string[] = [
    'explore',
    'emoji_objects',
    'verified_user',
    'security',
    'language',
    'watch_later',
  ];
  
  readonly languages = {
    [Languages.Hu]: WHY_US_DATA,
    [Languages.De]: WHY_US_DATA_DE,
    [Languages.En]: WHY_US_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }

  getIconAnimation(
    view: 'mobile' | 'desktop',
    iconIdx: number
  ): string {
    const left = 'fade-up-left';
    const right = 'fade-up-right';
    if (view === 'mobile') {
      return iconIdx % 2 ? right : left;
    } else if (view === 'desktop') {
      return iconIdx < 3 ? right : left;
    }
  }
}
