import { ServicesDataModel } from './services-data-model';

export const SERVICES_DATA_DE: ServicesDataModel = {
    pageTitle: 'Unsere Dienstleistungen',
    pageDescription: 'Unsere Haupttätigkeit ist die CNC-Metallbearbeitung, und wir bieten Ihnen unsere professionelle Hilfe für die Bearbeitung der Komponente aus verschiedenen Material. Die Grundstoffen für die Bearbeitung enthalten alles von die verschiedenen Polyamide und Nichteisenmetalle bis Edelstahl einbezüglich. Wir liefern die bestellten Produkte auf der Grundlage der angegebenen Pläne weltweit von dem Schlachten durch die Wärme-und Oberflächenbehandlung nach fertig gepackte Produkte, bei Bedarf mit einem VDA-oder PPAP Messprotokoll.',
    serviceTypes: [
        {
            title: 'Rohstofflagerung',
            description: 'Ein eigener Rohstofflager sorgt für die schnelle Produktionsprozesse.'
        },
        {
            title: 'Produktentwicklung, Beratung',
            description: 'Zu den kosteneffizienten und kürzen Herstellungsprozessen bieten wir professionelle Entwicklungs- und Reparaturideen. Gemeinsam mit unseren Partnern denken wir über jedes Produkt und jede Geschäftslösung nach, um die besten Ergebnisse zu erzielen - wir stehen gerne für Ihre Anfrage zur Verfügung.'
        },
        {
            title: 'Lagerservice',
            description: 'Lagerhaltung von Rohstoffen und Fertigprodukten in Zusammenarbeit festgelegt um die Logistikkosten unserer Partner zu senken und die plötzlich erscheinte Kundenbedürfnisse problemlos zu erfüllen.'
        },
        {
            title: 'Transport',
            description: 'Schneller, nachvollziehbarer und sicherer Versand durch unsere internationalen Logistikpartner.'
                            
        }
    ]
};