import { TranslateService } from '@ngx-translate/core'
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum Languages {
  Hu = 'hun',
  De = 'de',
  En = 'en'
}

@Injectable()
export class LanguageService {

  private readonly _language = new BehaviorSubject<Languages>(undefined);
  readonly language$ = this._language.asObservable()
    .pipe(filter(lang => !!lang));

    initialize(): void {
      setTimeout(() => this._determineLanguage());
    }

    private _determineLanguage(): void {
      if (origin.endsWith('.com')) {
        this._language.next(Languages.En);
      } else if (origin.endsWith('.at') || origin.endsWith('.de')) {
        this._language.next(Languages.De);
      } else if (origin.endsWith('.eu')) {
        this._language.next(Languages.Hu);
      } else {
        this._language.next(Languages.En);
      }
    }

  setLanguage(
    language: Languages
  ) {
    this._language.next(language);
  }
}
