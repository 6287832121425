import { AboutDataModel } from './about-data-model';

export const ABOUT_DATA: AboutDataModel = {
    pageTitle: 'Bemutatkozunk',
    pageDescription: 'A CNC technológiával történő fémmegmunkálás területén több évtizednyi tapasztalattal rendelkezünk és minden munkatársunk, beszállítónk, alvállalkozónk elkötelezett a kimagasló minőség fenntartása mellett. Célunk, hogy kiterjedt alkatrész gyártási megoldásokat kínáljunk Partnereink számára, amely magában foglalja a szükséges hő és felületkezelési folyamatok megszervezését és felügyeletét. Modern gépparkkal rendelkezünk, továbbá a gyártás mellett ajánljuk termékfejlesztési, terméktervezési folyamatokban szerzett szakmai tapasztalatunkat. Központunk a Szigetszentmiklósi Ipari Parkban helyezkedik el, Partnereinkkel hosszú távú együttműködésre törekszünk, melyet raktározási és konszignációs szolgáltatásainkkal is tudjuk támogatni.',
    cards: [       
        {
            title: 'Minőség',
            description: 'Munkatársunk, beszállítóink, alvállalkozóink elkötelezettek a minőség fenntartása mellett.'
            
        },
        {
            title: 'Precizitás',
            description: 'Kiterjedt megoldásokat kínálunk a Partnereink számára.'
        },
        {
            title: 'Együttműködés',
            description: 'Hosszú távú együttműködésre törekszünk, melyet változatos szolgáltatásainkkal támogatunk.'
        }
    ]
};

