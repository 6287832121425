import { HeaderDataModel } from './header-data-model';

export const HEADER_DATA: HeaderDataModel = {
     menuItems: {
         about: 'Rólunk',
         contact: 'Kapcsolat',
         machines: 'Gépparkunk',
         quality: 'Minőség',
         services: 'Szolgáltatások',
         workPieces: 'Munkadarabok'
     }
};