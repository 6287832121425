import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[scrollTo]'
})
export class ScrollToDirective {

    @HostListener('click', ['$event'])
    onClick(
        event: MouseEvent
    ) {
        event.stopPropagation();
        console.log("button");
    }
}