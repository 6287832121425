import { QualityDataModel } from './quality-data-model';

export const QUALITY_DATA: QualityDataModel = {
    pageTitle: 'Minőség',
    pageDescription:   'Cégfilozófiánkban megjelenítjük személyes és üzleti tapasztalatainkat, miszerint minőséget csak kimagasló technikai megoldásokkal és rendszeresen karbantartott gépparkkal, hibátlan és folyamatosan ellenőrzött alapanyagokkal, szerszámokkal és mérőeszközökkel lehet létrehozni. Cégünk természetesen integrált ISO minősítéssel rendelkezik.',
    certData: [
        {
            title: 'ISO 9001:2015',
            url: '/assets/quality/1_magyar.png',
        },{
            title: 'ISO 14001:2015',
            url: '/assets/quality/2_magyar.png'
        }
    ]
}
