import { ServicesDataModel } from './services-data-model';

export const SERVICES_DATA: ServicesDataModel = {
    pageTitle: 'Szolgáltatásaink',
    pageDescription: 'Fő tevékenységünk a CNC fém megmunkálás valamint szakmai segítségünket ajánljuk különböző alapanyagból készült alkatrészek megmunkálásához. A feldolgozásra kerülő alapanyagok a különböző polyamidoktól és a színesfémektől a rozsdamentes acélokig bezárólag mindent magába foglal. A megadott tervek alapján a darabolástól a hő- és felületkezelésen át készre csomagolva, igény esetén VDA vagy PPAP rendszer szerint kidolgozott mérési jegyzőkönyvvel szállítjuk a megrendelt termékeket világszerte.',
    serviceTypes: [
        {
            title: 'Alapanyag raktározás',
            description: 'Saját alapanyagraktárunk biztosítja gyors gyártási folyamatainkat.'
        },
        {
            title: 'Termékfejlesztés, konzultáció',
            description: 'A költséghatékonyabb és rövidebb gyártási folyamatok eléréséhez biztosítjuk szakmai fejlesztési, javítási ötleteinket. Partnereinkkel együtt végig gondolunk minden egyes terméket és üzleti megoldást a legjobb eredmény elérésének érdekében - igény esetén angol és német nyelven is állunk rendelkezésükre.'
        },
        {
            title: 'Raktározási szolgáltatás',
            description: 'Az együttműködésben meghatározott alapanyag és késztermék készletezése, csökkentve ezzel Partnereink logisztikai költségét valamint hirtelen megjelenő vevői igények probléma nélküli kiszolgálását.'
        },
        {
            title: 'Szállítás',
            description: 'Gyors, nyomon követhető és biztonságos szállítás nemzetközi logisztikai partnereinken keresztül.'
                            
        }
    ]
};