import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { element } from 'protractor';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit {
    static activeClass = 'active';
    
    @Input()
    targetSelector: string;

    @Input()
    scrollOffset = 0;

    @Output()
    readonly sectionChange = new EventEmitter<string>();


    private currentSection: HTMLElement;
    private childrenData: {
        element: HTMLElement;
        offset: number;
    }[] = [];
    constructor(private _el: ElementRef<HTMLElement>) {}

    ngAfterViewInit(): void {
        for (let i=0; i < this._el.nativeElement.children.length; ++i) {
            const child = this._el.nativeElement.children[i] as HTMLElement;
            const ignore = !child.id;
            if (!ignore) {
                this.childrenData.push({
                    element: child,
                    offset: Number.parseInt(child.getAttribute('scroll-offset')) || 0
                });
            }
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        event.stopPropagation();
        let currentSection: HTMLElement;
        const scrollTop = document.body.parentElement.scrollTop;
        const parentOffset = this._el.nativeElement.offsetTop;
        for (let i = 0; i < this.childrenData.length; i++) {
            const elementData = this.childrenData[i];
            const sectionActive = (elementData.element.offsetTop - parentOffset + elementData.offset) <= scrollTop + this.scrollOffset;
            if (sectionActive) {
                currentSection = elementData.element;
            }
        }

        const shallUpdate = currentSection !== this.currentSection;
        if (shallUpdate && currentSection) {
            this.currentSection = currentSection;
            // this.sectionChange.emit(this.currentSection);
            const a: HTMLElement = document.querySelector(`${this.targetSelector} a[href="#${currentSection.id}"]`);
            document.querySelector(`${this.targetSelector} `)
            const listItems = a.parentElement.parentElement.children;
            for (let i = 0; i < listItems.length; ++i) {
                listItems[i].classList.remove(ScrollSpyDirective.activeClass)
            }
            if (currentSection) {
                a.parentElement.classList.add(ScrollSpyDirective.activeClass);
            }
        }
    }

}