import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Languages, LanguageService } from './language-change.service';

export abstract class PageContentTranslateBase<TData> implements OnDestroy {
    
    abstract readonly languages: Record<Languages, TData>
    data: TData;

    protected readonly _unsubscribe = new Subject<void>();

    constructor(
        protected languageService: LanguageService,
    ) {
        this.languageService.language$
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(lang => {
                this.data = this.languages[lang];
            });
    }

    protected initialize(): void {
        this.data = this.languages[Languages.Hu];
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}