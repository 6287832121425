import { HeaderDataModel } from './header-data-model';

export const HEADER_DATA_EN: HeaderDataModel = {
     menuItems: {
         about: 'About us',
         contact: 'Contact',
         machines: 'Machinery',
         quality: 'Quality',
         services: 'Services',
         workPieces: 'Workpieces'
     }
};