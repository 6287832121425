import { WhyUsDataModel } from './why-us-data-model';

export const WHY_US_DATA: WhyUsDataModel = {
    pageTitle: 'Miért válasszon minket?',
    pageDescription: 'Mérnökeink több évtizedes tapasztalattal rendelkeznek a forgácsolásban, gyártásban, autóiparban, automatizálásban, felületkezelési technológiákban.',
    list: [
        {
            title: 'Tapasztalat',
            description: 'Nemzetközi piacon szerzett tapasztalat, a sztenderdek, üzleti elvárások ismerete.'
        },
        {
            title: 'Rugalmasság',
            description: 'Szervezetünk ill., felépítésünk miatti rugalmasság, testre szabható megoldások.'
        },
        {
            title: 'Garancia',
            description: 'Régóta fennálló, folyamatosan ellenőrzött, minőségi beszállítói háttér.'
        },
        {
            title: 'Biztonságos',
            description: 'Gyors, nyomon követhető és biztonságos szállítás nemzetközi logisztikai partnereinken keresztül.'
        },
        {
            title: 'Többnyelvűség',
            description: 'Angol, német nyelv ismerete.'
        },
        {
            title: 'Gyorsaság',
            description: 'Rövid határidők a gyártásban.'
        }
    ]
}