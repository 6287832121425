import { WelcomeDataModel } from './welcome-data-model';

export const WELCOME_DATA: WelcomeDataModel = {
    contact: 'Kapcsolat felvétel',
    experiences: [
        'Forgácsolásban',
        'Gyártásban',
        'Hő És Felületkezelésben',
        'Automatizálásban',
    ],
    heading: 'Több évtizedes tapasztalat'
};