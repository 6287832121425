import { WelcomeDataModel } from './welcome-data-model';

export const WELCOME_DATA_EN: WelcomeDataModel = {
    contact: 'Contact us',
    experiences: [
        'Machining',
        'Manufacturing',
        'Automation',
        'Surface Treatment'

    ],
    heading: 'Decades of experience in'
};