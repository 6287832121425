import { Component, OnInit } from '@angular/core';
import { ABOUT_DATA } from './data/about-data-hu';
import { ABOUT_DATA_DE } from './data/about-data-de';
import { ABOUT_DATA_EN } from './data/about-data-en';

import { AboutDataModel } from './data/about-data-model';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends PageContentTranslateBase<AboutDataModel>  {

  readonly imageUrls: string[] = [
    '/assets/icon-images/001.png',
    '/assets/icon-images/002.png',
    '/assets/icon-images/003.png'
  ];

  readonly languages = {
    [Languages.Hu]: ABOUT_DATA,
    [Languages.De]: ABOUT_DATA_DE,
    [Languages.En]: ABOUT_DATA_EN
  };

  data: AboutDataModel = ABOUT_DATA;

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }
}
