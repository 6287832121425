import { Component, OnInit } from '@angular/core';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { WelcomeDataModel } from './data/welcome-data-model';
import { WELCOME_DATA } from './data/welcome-data';
import { WELCOME_DATA_DE } from './data/welcome-data-de';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { WELCOME_DATA_EN } from './data/welcome-data-en';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent extends PageContentTranslateBase<WelcomeDataModel> implements OnInit {

  emailaddress = 'contact@proactivecnc.eu'; // TODO: trick!
  emailsubject = 'Kapcsolat felvétel';
  videoEnabled = false;

  readonly languages = {
    [Languages.Hu]: WELCOME_DATA,
    [Languages.De]: WELCOME_DATA_DE,
    [Languages.En]: WELCOME_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }

  ngOnInit(): void {
    this.videoEnabled = $(document).width() > 767;
  }

  navigateToAbout(
    $event: MouseEvent
  ): void {
    $event.preventDefault();
    $('html, body').stop().animate({
      scrollTop: $('#about').offset().top - 60
    }, 800, () => $(window).click());
  }
}
