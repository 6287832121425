
import { AboutDataModel } from './about-data-model';

export const ABOUT_DATA_DE: AboutDataModel = {
    pageTitle: 'Über uns',
    pageDescription: 'Wir haben jahrelange Erfahrungen in der Metallbearbeitung mit der CNC-Technologie und alle unsere Mitarbeiter, Lieferanten und Unterlieferanten verpflichten sich zur Aufrechterhaltung der hervorragende Qualität. Unser Ziel ist, umfassende Komponenten-Fertigung Lösungen für unsere Partnern zu bieten, die Organisation und Überwachung der notwendige Wärme- und Oberflächenbehandlung-Prozesse beeinhaltet. Wir haben einen modernen Machinenpark, und neben der Herstellung empfehlen wir unsere Berufserfahrungen in Produktentwicklungs-und Produktdesingprozessen. Unser Zentrum befindet sich im Industriepark in Szigetszentmiklós, wir streben mit unseren Partnern langfristige Zusammenarbeit, die wir mit Lagerung- und Kommissionierung Dienstleistungen unterstützen können.',
    cards: [       
        {
            title: 'Qualität',
            description: 'Alle unsere Mitarbeiter, Lieferanten und Unterlieferanten verpflichten sich zur Aufrechterhaltung der hervorragende Qualität'
            
        },
        {
            title: 'Präzision',
            description: 'Unser Ziel ist, umfassende Komponenten-Fertigung Lösungen für unsere Partnern zu bieten'
        },
        {
            title: 'Zusammenarbeit',
            description: 'Wir streben mit unseren Partnern langfristige Zusammenarbeit'
        }
    ]
};

