import { QualityDataModel } from './quality-data-model';

export const QUALITY_DATA_EN: QualityDataModel = {
    pageTitle: 'Quality',
    pageDescription:   'Our corporate philosophy reflects our personal and professional experiences, that is, quality can only be achieved through excellent technical solutions and regular maintenance of our machinery, by using impeccable and continuously monitored materials, tools and measuring devices. Our company has been granted the integrated ISO certificate.',
    certData: [
        {
            title: 'ISO 9001:2015',
            url: '/assets/quality/1_angol.png',
        },{
            title: 'ISO 14001:2015',
            url: '/assets/quality/2_angol.png'
        }
    ]
}
