import { Component, OnInit, AfterViewInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LanguageService } from './utils/language-change.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private lang: LanguageService
  ) {

  }

  ngOnInit(): void {
    AOS.init({
      once: true,
      offset: 120
    });
  }

  ngAfterViewInit(): void {
    this.lang.initialize();
  }
}
