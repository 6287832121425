import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { LanguageService, Languages } from '../../utils/language-change.service';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { HeaderDataModel } from './data/header-data-model';
import { HEADER_DATA } from './data/header-data';
import { HEADER_DATA_DE } from './data/header-data-de';
import { takeUntil } from 'rxjs/operators';
import { HEADER_DATA_EN } from './data/header-data-en';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends PageContentTranslateBase<HeaderDataModel> implements OnInit, AfterViewInit {

  readonly Languages = Languages;
  private styleClass = 'toggled';
  private headerSelector = '.header.navbar';

  languageSelector: Record<Languages, string> = {
    [Languages.Hu]: 'HU',
    [Languages.De]: 'DE',
    [Languages.En]: 'EN'
  };
  
  readonly languages = {
    [Languages.Hu]: HEADER_DATA,
    [Languages.De]: HEADER_DATA_DE,
    [Languages.En]: HEADER_DATA_EN
  };

  selectedLanguage = Languages.Hu;
  menuIsOpen = false;

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
    this.languageService.language$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(lang => {
        this.selectedLanguage = lang;
      });
  }
  
  ngOnInit(): void {
    $(document).scroll(() => this.updateStyle());
    this.updateStyle();
  }

  switchLanguage(
    $event: MouseEvent,
    language: Languages
  ): void {
    $event.stopPropagation();
    this.languageService.setLanguage(language);
    // if (this.menuIsOpen) {
    //   setTimeout(() => {
    //     $('.navbar-toggler').click();
    //   }, 500);
    // }
  }
  ngAfterViewInit(): void {
    this.initializeNavigation();
  }

  toggleButtonClick() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  private updateStyle(): void {
    if($(document).scrollTop() >= 100 ) {
      $(this.headerSelector).addClass(this.styleClass);
    }
    else {
      $(this.headerSelector).removeClass(this.styleClass);
    }
  }

  private initializeNavigation(): void {
    $(".header a").on('click', event => {
      const anchor = event.target as HTMLAnchorElement;
      const hash = anchor.hash;
      if (hash !== "") {
        event.preventDefault();
        const togglerButton = $('.navbar-toggler');
        const mobileView = togglerButton.attr('aria-expanded') === 'true';
        if (mobileView) {
          togglerButton.click();
        }
        $('html, body').stop().animate({
          scrollTop: $(hash).offset().top - 50
        }, 800);
      }
    });
  }

}
