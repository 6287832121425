import { WelcomeDataModel } from './welcome-data-model';

export const WELCOME_DATA_DE: WelcomeDataModel = {
    contact: 'Kontakt',
    experiences: [
        'Schalttisch',
        'Produktion',
        'Automatisierung',
        'Oberflächenbehandlung'
    ],
    heading: 'Jahrelange Erfahrungen'
};