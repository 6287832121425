import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, interval } from 'rxjs';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { ServicesDataModel } from './data/services-data-model';
import { SERVICES_DATA_DE } from './data/services-data-de';
import { SERVICES_DATA_EN } from './data/services-data-en';
import { SERVICES_DATA } from './data/services-data';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent extends PageContentTranslateBase<ServicesDataModel> implements OnInit, OnDestroy {

  readonly imgUrls: string[] = [
    '/assets/services/materialstore.png',
    '/assets/services/development.png',
    '/assets/services/logistics.png',
    '/assets/services/transfer.png'
  ]; 
  activeTabIdx = 0;

  private unsubscribe = new Subject<void>();

  readonly languages = {
    [Languages.Hu]: SERVICES_DATA,
    [Languages.De]: SERVICES_DATA_DE,
    [Languages.En]: SERVICES_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }

  
  ngOnInit(): void {
    interval(8000).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      this.activeTabIdx = ++this.activeTabIdx % 4;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  selectedIndexChanged(
    index
  ): void {
    
  }

}
