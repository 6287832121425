import { WhyUsDataModel } from './why-us-data-model';

export const WHY_US_DATA_EN: WhyUsDataModel = {
    pageTitle: 'Why choose us?',
    pageDescription: 'Our engineers have several years of experience in machining, manufacturing, automation, surface treatment and in the automotive industry.',
    list: [
        {
            title: 'Experience',
            description: 'Experience in the international markets, knowledge of the business standards and expectations.'
        },
        {
            title: 'Flexibility',
            description: 'We are able to provide tailor-made and flexible solutions due to our organizational structure.'
        },
        {
            title: 'Guarantee',
            description: 'We have a long-standing network of suppliers that we regularly monitor.'
        },
        {
            title: 'Safe',
            description: 'We provide fast, traceable and secure delivery through our intrenational logistic partners.'
        },
        {
            title: 'International',
            description: 'We speak English and German.'
        },
        {
            title: 'Fast',
            description: 'Short deadlines for manufacturing are guaranteed.'
        }
    ]
}