import { ServicesDataModel } from './services-data-model';

export const SERVICES_DATA_EN: ServicesDataModel = {
    pageTitle: 'Services',
    pageDescription: 'Our principal activity consists of CNC metalworking and professional assistance in processing components of different materials. The raw materials we process include different polyamides, non-ferrous metals, and stainless steel among others. We are able to carry out the whole process, starting from chopping through surface and heat treatment to packaging the finished product and deliver it worldwide. If needed, we can deliver the product with mesurement record according to VDA or PPAP system.',
    serviceTypes: [
        {
            title: 'Warehousing of raw materials',
            description: 'The company\'s own warehuse of raw materials assures fast manufacturing processes.'
        },
        {
            title: 'Product development, consultation',
            description: 'We provide ideas for professional development and improvement in order to achieve cost-effective and shorter manufacturing processes. We consider various products and business solutions together with our Partners to achieve the best results - the consultations can be carried out in English or German if needed.'
        },
        {
            title: 'Warehousing service',
            description: 'We are able to stock the raw material and finished products defined during the cooperation, with the intention of decreasing our Partners\' logistics costs and answering the sudden needs of our customers without any delays.'
        },
        {
            title: 'Delivery',
            description: 'We provice fast, traceable and secure delivery through our intrenational logistic partners.'
                            
        }
    ]
};