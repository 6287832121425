import { Component } from '@angular/core';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { QUALITY_DATA_DE } from './data/quality-data-de';
import { QualityDataModel } from './data/quality-data-model';
import { QUALITY_DATA } from './data/quality-data';
import { QUALITY_DATA_EN } from './data/quality-data-en';


@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss']
})
export class QualityComponent extends PageContentTranslateBase<QualityDataModel> {

  readonly languages = {
    [Languages.Hu]: QUALITY_DATA,
    [Languages.De]: QUALITY_DATA_DE,
    [Languages.En]: QUALITY_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }
}
