import { HeaderDataModel } from './header-data-model';

export const HEADER_DATA_DE: HeaderDataModel = {
     menuItems: {
         about: 'Über uns',
         contact: 'Kontakt',
         machines: 'Machinenpark',
         quality: 'Qualität',
         services: 'Leistungen',
         workPieces: 'Werkstücke'
     }
};