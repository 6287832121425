import { Component } from '@angular/core';
import { MachinesDataModel } from './data/machines-data-model';
import { PageContentTranslateBase } from 'src/app/utils/page-content-translate-base';
import { Languages, LanguageService } from 'src/app/utils/language-change.service';
import { MACHINES_DATA_DE } from './data/machines-data-de';
import { MACHINES_DATA } from './data/machines-data';
import { MACHINES_DATA_EN } from './data/machines-data-en';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss']
})
export class MachinesComponent extends PageContentTranslateBase<MachinesDataModel> {

  readonly languages = {
    [Languages.Hu]: MACHINES_DATA,
    [Languages.De]: MACHINES_DATA_DE,
    [Languages.En]: MACHINES_DATA_EN
  };

  constructor(
    languageService: LanguageService
  ) {
    super(languageService);
    this.initialize();
  }
}
